




























import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/components/common/Modal.vue'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const moment = require('moment')

@Component({
  components: {
    Modal
  }
})
export default class Negative extends Vue {
  @Prop({ type: String, required: true, default: '' })
  private img!: string

  @Prop({ type: String, required: true, default: '' })
  private title!: string

  @Prop({ type: String, required: false, default: '' })
  private description!: string

  @Prop({ type: String, required: true, default: '' })
  private btnText!: string

  @Prop({ type: Boolean, required: false, default: false })
  private isTime!:boolean

  @Prop({ type: Boolean, required: false, default: false })
  private restartAction!:boolean

  private goTo (): void {
    this.$router.push('/welcome')
  }

  private isModal = false

  private mounted (): void {
    if (window.innerWidth < this.$data.mobileWidth) {
      this.$root.$on('mobile-go-back', () => {
        this.$router.push('/welcome')
      })
    }
  }

  private destroyed (): void {
    if (window.innerWidth < this.$data.mobileWidth) this.$root.$off('mobile-go-back')
  }

  get expiredDate (): string {
    const date = this.$store.getters['walkthrough/testResultsExpirationTime']
    const date2:Date = new Date(date)
    return moment(date2).format('MM/DD/YYYY') || ''
  }

  get expiredTime (): string {
    const date = this.$store.getters['walkthrough/testResultsExpirationTime']
    const date2:Date = new Date(date)
    return moment(date2).format('h:mm A') || ''
  }

  private restart (): void {
    this.$store.dispatch('walkthrough/clearResults')
      .then(() => {
        sessionStorage.clear()
        this.$router.push('/welcome')
      })
  }
}
